.homepage-container {
  position: relative;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  min-height: 650px;
}

.left-content {
  flex: 9.5;
  padding-right: 40px;
}

.right-sidebar {
  flex: 2.5;
  padding-left: 15px;
  border-left: 2px solid #eaeaea;
}

h1,
h2,
h3 {
  margin: 0 0 10px;
  color: #00bfa5;
}

h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0;
}

h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 10px;
}

.section h3 {
  margin-bottom: 10px;
  color: #00bfa5;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 5px;
}

.section h4 {
  font-size: 1.2em;
  color: #333;
}

.section p {
  margin-bottom: 5px;
  color: #666;
}

.section ul {
  list-style: disc;
  margin-left: 10px;
}

.section ul li {
  margin-bottom: 1px;
  color: #666;
}

.homepage-container .avatar-introduction {
  display: flex;
  padding: 20px;
  flex-direction: row; /* Default layout for larger screens */
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.homepage-container .avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 50px;
}

.homepage-container .avatar {
  width: 400px;
  height: 400px;
  border-radius: 8%;
  object-fit: cover;
}

.homepage-container .introduction {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
  text-justify: auto;
  font-size: 20px;
  font-weight: 350;
  line-height: 30px;
  padding: 50px;
  max-width: 600px;
  /* margin: 0; */
}

/* Tablet Styles */
@media (max-width: 768px) {
  .left-content,
  .right-sidebar {
    flex: 1 1 100%;
    padding: 10px;
    border: none;
  }

  .homepage-container {
    min-height: auto;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.3em;
  }

  .avatar-introduction {
    flex-direction: column;
    align-items: center;
  }

  .homepage-container .avatar-container {
    margin: 20px;
  }

  .homepage-container .avatar {
    width: 300px;
    height: 300px;
  }

  .homepage-container .introduction {
    font-size: 18px;
    padding: 20px;
    max-width: 100%;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .left-content,
  .right-sidebar {
    flex: 1 1 100%;
    padding: 20px;
    border: none;
  }

  .homepage-container {
    min-height: auto;
    flex-direction: column;
    margin-top: 30px;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.2em;
  }

  .homepage-container .avatar-introduction {
    flex-direction: column;
    align-items: center;
    justify-items: center;
    align-self: center;
    justify-content: center;
    
  }

  .homepage-container .avatar-container {
    align-items: center;
    justify-items: center;
    align-self: center;
    justify-content: center;
    
  }

  .homepage-container .avatar {
    
    width: 150px;
    height: 150px;
    align-self: center;

    align-items: center;
    justify-items: center;
    align-self: center;
    justify-content: center;
  }

  .homepage-container .introduction {
    font-size: 16px;
    padding: 15px;
    /* max-width: 100%; */
    margin-top: 10px;

    align-items: center;
    justify-items: center;
    align-self: center;
    justify-content: center;
  }
}
