.footer {
    /* position: fixed; Make the footer fixed at the bottom */
    bottom: 0;
    width: 100%;
    background-color: white;
    color: #555;
    opacity: 0.95; /* Match the navigation bar opacity */
    padding: 10px 0px; 
    margin: 0;
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .footer .copyRight{
    text-align: center;
  }
  