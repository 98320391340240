.header {
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
}

.heading {
  display: flex;
  max-width: 1100px;
  justify-content: space-between;

}

.nameAndProfession {
  flex: 9.5;
  text-align: left;
}

.contact {
  flex: 2.5;
  text-align: left;
  padding-left: 15px;
  border-left: 2px solid #eaeaea;

  color: #333;

  font-size: 1rem;
  font-weight: 200;
}

.header h1 {
  font-size: 2.5em;
  font-weight: bold;
  color: #00bfa5;
  margin-bottom: 5px;
}

.header h2 {
  /* font-size: 1.5em; */
  color: #333;
  margin-bottom: 20px;

  font-size: 1.25rem;
  font-weight: 300;
}

.avatarAndSummary {
  display: flex;
}

.avatarAndSummary .avatar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatarAndSummary .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.avatarAndSummary p {
  margin-left: 30px;
  line-height: 1.5;
  color: #666;
  text-align: left;
}

.externalLinkItems a {
  margin-left: 20px;
}