.interests {
    margin-bottom: 40px;
  }
  
  .interests h3 {
    font-size: 1.5em;
    color: #00bfa5;
    margin-bottom: 20px;
    border-bottom: 2px solid #eaeaea;
    padding-bottom: 5px;
  }
  
  .interests p {
    margin-bottom: 5px;
    color: #666;
  }
  