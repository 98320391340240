.work-experience {
  margin-bottom: 5px;
}

.work-experience h3 {
  font-size: 1.5em;
  color: #00bfa5;

}

.work-experience .job h4 {
  font-size: 1.3em;
  color: #333;
  font-weight: 400;
}

.work-experience .job p {
  /* margin-bottom: 5px; */
  color: #666;
}

.work-experience .job ul {
  list-style: disc;
  margin-left: 0px;
}

.work-experience .job ul li {
  /* margin-bottom: 5px; */
  color: #666;
  text-align: left;
}

.work-experience .job  .designationCompanyTimePeriod{
  display: flex;
  align-items: center;
}

.work-experience .job .jobDesignation{
  flex: 7;
  text-align: left;
  /* align-items: center; */
}

.work-experience .job .companyAndTimePeriod{
  flex: 5;
  text-align: left;
}

